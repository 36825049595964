import { TimelineMax, TweenMax, Expo } from 'gsap';

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  createMenuTimeline(startCallback, reverseCallback) {
    const mainNav = document.getElementById('main-nav');
    const bg = mainNav.querySelector('.nav-primary--background');
    const items = mainNav.querySelectorAll('.nav-primary--main li');
    const ancillary = mainNav.querySelectorAll('.nav-primary--ancillary li');
    const tl = new TimelineMax({paused: true, onStart: startCallback, onReverseComplete: reverseCallback});

    tl.set(mainNav, {display: 'block'});
    tl.fromTo(bg, 0.5, {height: '0%'}, {height: '100%', ease: Expo.easeInOut}, 0);
    items.forEach((item, idx) => {
      const title = item.querySelector('.title');
      const subtitle = item.querySelector('.subtitle');
      tl.add([
        TweenMax.fromTo(title, 0.5, {alpha: 0, y: '+=5vw'}, {alpha: 1, y: 0, ease: Expo.easeInOut}),
        TweenMax.fromTo(subtitle, 0.5, {alpha: 0, x: '-=5vw'}, {alpha: 1, x: 0, ease: Expo.easeInOut}),
      ], 0.25 + (0.1*idx), 'start', 0.25);
    });
    ancillary.forEach((item) => {
      tl.fromTo(item, 0.5, {alpha: 0, x: '-=5vw'}, {alpha: 1, x: 0, ease: Expo.easeInOut}, '-=0.4');
    });

    return tl;
  },

  init() {
    $('.slideshow').slick({
      dots: true,
      slide: '.slide',
      appendDots: '.slick-toolbar .slick-dots-wrapper',
      nextArrow: '.slick-toolbar .slick-next',
      prevArrow: '.slick-toolbar .slick-prev',
      adaptiveHeight: false,
      autoplay: true,
    });

    const main = $('.container-fluid.wrap[role="document"]');
    const header = $('header.banner');
    const menuButton = $('.hamburger');
    const tl = this.createMenuTimeline(() => {
      $('body').addClass('menu-is-open');
    }, () => {
      $('body').removeClass('menu-is-open');
    });
    
    menuButton.on('click', () => {
      $(menuButton).toggleClass('is-active');

      if (!$('body').hasClass('menu-is-open')) {
        tl.timeScale(1).play();
      } else {
        tl.timeScale(2).reverse();
      }
    });

    $('body').on('click', 'a.jump', function (e) {
      e.preventDefault();

      const block = $(this).data('block') || 'start';
      
      document.querySelector($(this).attr('href')).scrollIntoView({
        behavior: 'smooth',
        block,
      });
    });

    /**
     * Expandable Component
     */
    const approach = document.querySelectorAll('.expandable-section');

    approach.forEach((el) => {
      $('a.expand', el).on('click', (e) => {
        const btn = $(e.currentTarget);
        const span = btn.find('> span');
        $(el).toggleClass('expanded');

        const expanded = $(el).hasClass('expanded');
        btn.attr('aria-expanded', expanded ? 'true' : 'false');
        span.text(expanded ? 'Hide' : 'Show');
        $('.expandable-section--content', el).css({display: expanded ? 'block' : ''});
      });
    });

    // Resize
    const resize = debounce(function() {
      main.css({paddingTop: header.outerHeight()});
    }, 100);

    window.addEventListener('resize', resize);
    window.addEventListener('load', resize);
    resize();
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
