import { TimelineMax, TweenLite, Back } from 'gsap';
import portfolio from './portfolio';

function explodeText(text) {
  const words = text.split(' ');
  const arr = [];
  
  for (let i = 0, len = words.length; i<len; i++) {
    const wordEl = document.createElement('div');
    wordEl.classList.add('word');

    words[i].split('').forEach(c => {
      const letterEl = document.createElement('div');
      letterEl.innerText = c;
      
      wordEl.appendChild(letterEl);
    });
    
    arr.push(wordEl);
  }

  return arr;
}

export default {
  debounce: false,

  handleScroll(e) {
    if ($('body').hasClass('modal-open')) {
      return;
    }

    e.preventDefault();

    if (this.debounce) {
      return;
    }

    const direction = e.originalEvent.deltaY >= 0 ? 'down' : 'up';
    if (direction === 'down') {
      if (this.activeSectionIndex < this.sections.length - 1) {
        this.setActiveSectionIndex(this.activeSectionIndex + 1);
      }
    } else if (direction === 'up') {
      if (this.activeSectionIndex > 0) {
        this.setActiveSectionIndex(this.activeSectionIndex - 1);
      }
    }

    this.debounce = true;
    setTimeout(() => {
      this.debounce = false;
    }, 1000);
  },

  setActiveSectionIndex(idx) {
    this.activeSectionIndex = idx;

    setTimeout(() => {
      this.sections[this.activeSectionIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    })
  },

  init() {
    portfolio.init();

    const headline = document.getElementById('main-headline');
    const explode = explodeText(headline.innerText);
    headline.innerText = '';

    for (let i = 0, len = explode.length; i<len; i++) {
      headline.appendChild(explode[i]);
    }

    const tl = new TimelineMax({delay: 1});

    explode.forEach((w, i) => {
      tl.add([
        TweenLite.from(w, 1, { alpha: 0}),
        TweenLite.from(w, 0.5, { y: '+=5vw', ease: Back.easeOut }),
      ], i*0.3);
    });

    $('a.jump').on('click', () => {
      this.activeSectionIndex = 1;
    });

    /**
     * Snap scroll
     */
    this.sections = $('section, footer');

    $(document).on('mousewheel', this.handleScroll.bind(this));
    
    setTimeout(() => {
      this.setActiveSectionIndex(0);
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
