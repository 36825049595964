import { TweenMax, Back } from 'gsap';

export default {
  modalEl: null,
  modalContentEl: null,
  modalClose: null,
  modalWrapper: null,
  modalBackground: null,
  modalScroll: null,
  mql: null,

  handleScroll(e) {
    const wH = $(window).height();
    let perc = e.target.scrollTop / wH;
    perc = perc >= 1 ? 1 : perc;
    console.log(wH, perc)

    // this.modalBackground.css({opacity: (perc * 0.4) + 0.3});
    this.modalScroll.css({opacity: 1 - perc, display: perc < 1 ? 'block' : 'none'});
  },

  openModal(e) {
    e.preventDefault();
    const href = $(e.currentTarget).attr('href');
    this.modalEl.style.display = 'block';

    $('body').addClass('modal-open');
    $('.loading').eq(0).addClass('show');
    
    $.get(href, (res) => {
      $(this.modalContentEl).html(res);

      this.modalWrapper = $('.portfolio-wrapper--inner', this.modalContentEl);
      this.modalBackground = $('.portfolio-wrapper--background', this.modalContentEl);
      this.modalScroll = $('.scroll-down.jump', this.modalContentEl);

      this.modalBackground.css({ opacity: 0.7 });

      // if (!this.modalWrapper.hasClass('hide-bg') && this.mql.matches) {
      if (!this.modalWrapper.hasClass('hide-bg')) {
        this.modalWrapper.on('scroll', this.handleScroll.bind(this));
      }

      const portfolio_image = window.localStorage.getItem('portfolio_image');
      if (portfolio_image) {
        var img = new Image();
        img.onload = () => {
          $('.loading').eq(0).removeClass('show');
          this.modalEl.classList.add('show');
        }
        img.src = portfolio_image;
      } else {
        $('.loading').eq(0).removeClass('show');
        this.modalEl.classList.add('show');
      }

      window.localStorage.removeItem('portfolio_image');
    });
  },

  closeModal() {
    this.modalWrapper.off('scroll', this.handleScroll.bind(this));

    this.modalEl.classList.remove('show');
    setTimeout(() => {
      this.modalEl.style.display = 'none';
      this.modalContentEl.innerHTML = '';
      $('body').removeClass('modal-open');
    }, 500);
  },

  init() {
    this.modalEl = document.getElementById('portfolioModal');

    this.modalClose = document.createElement('button');
    this.modalClose.classList.add('modal-close');
    this.modalClose.innerHTML = '<i class="icon-close"></i>';
    this.modalEl.appendChild(this.modalClose);

    this.modalContentEl = document.createElement('div');
    this.modalContentEl.classList.add('modal-content');
    this.modalEl.appendChild(this.modalContentEl);
    this.mql = window.matchMedia('(min-width: 576px)');

    $(this.modalClose).on('click', this.closeModal.bind(this));
    $(document).keyup((e) => {
      if (e.keyCode === 27) {
        this.closeModal();
      }
    })
    $('body').on(
      'click', 
      '.portfolio-item .link:not(".out"), .feature .feature--inner', 
      this.openModal.bind(this));

    TweenMax.staggerFrom($('.portfolio-item'), 0.5, {alpha: 0, y:'+=5vw', delay: 0.5, ease: Back.easeOut}, 0.1);

    $('.portfolio-item').on('touchstart', function() {
      $('.portfolio-item').removeClass('hover');
      $(this).toggleClass('hover');
    });

    /**
     * Portfolio Dropdown
     */
    const toggle = document.querySelector('.dropdown .dropdown-toggle');
    const dropdownItems = [].slice.call(document.querySelectorAll('.dropdown-item input'));
    const portfolioItems = [].slice.call(document.querySelectorAll('.portfolio-item'));
    const list = document.querySelector('.portfolio-list');

    $('body.portfolio').on('click', '.dropdown-item', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const item = e.currentTarget;

      if (item.nodeName === 'LABEL') {
        const input = item.querySelector('input');
        const checked = dropdownItems.filter(itm => itm.checked);

        // Prevent from checking none.
        if (checked.length === 1 && checked[0] === input) {
          return;
        }

        input.checked = !input.checked;
      } else if (item.nodeName === 'A') {
        const term = $(item).data('term');

        if (term === 'all') {
          dropdownItems.forEach(itm => itm.checked = true);
        }
      }

      const checked = dropdownItems.filter(itm => itm.checked);
      const selectedTerms = checked.map(itm => $(itm).data('term'));
      const txt = checked.length < dropdownItems.length ?
        checked.map(itm => $(itm).data('name')).join(', ') :
        'All Companies';

      toggle.innerText = txt;

      /**
       * Set list from checked items
       */

      const sections = portfolioItems.reduce((acc, itm) => {
        const terms = $(itm).data('terms').split(',');

        if (terms.some(t => selectedTerms.indexOf(t) !== -1)) {
          acc.matched = [...acc.matched, itm];
        } else {
          acc.unmatched = [...acc.unmatched, itm]
        }
        return acc;
      }, {
        matched: [],
        unmatched: [],
      });

      list.innerHTML = '';
      
      sections.matched.forEach((itm) => {
        itm.classList.remove('unmatched');
        list.appendChild(itm);
      });

      sections.unmatched.forEach((itm) => {
        itm.classList.add('unmatched');
        list.appendChild(itm);
      });

      TweenMax.killTweensOf(portfolioItems);
      TweenMax.staggerFromTo(portfolioItems, 0.5, 
        {opacity: 0, y:'+=5vw', delay: 0.5}, 
        {opacity: 1, y:'0', ease: Back.easeOut}, 
        0.1);
    });
  },

  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
