// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap.js"

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import portfolio from './routes/portfolio';
import about from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home,
  portfolio,
  about,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
